import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import { Description } from '../StyledComponents'
import { Text } from '../../../../../components'

interface IProps {
	count?: number
	icon?: React.ReactNode
	title: string
	description: string
	alignCenter?: boolean
}

const ActionComponent: React.FC<IProps> = ({ count, icon, title, description, alignCenter }) => {
	const { t } = useTranslation()

	return (
		<Wrapper style={{ alignItems: alignCenter ? 'center' : 'flex-start' }}>
			{icon}
			<span>{count}</span>
			<StyledTitle zeroMargin>{title}</StyledTitle>
			<Description zeroMargin style={{ textAlign: alignCenter ? 'center' : 'left' }}>
				{description}
			</Description>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	max-width: 330px;
	display: flex;
	flex-direction: column;
	padding: ${SPACES.EXTRA_SMALL};
	background: ${Colors.White};
	span {
		font-size: 48px;
		font-weight: 700;
		color: ${Colors.Black};
		padding-top: ${SPACES.EXTRA_LARGE};
		padding-bottom: ${SPACES.EXTRA_SMALL};
		line-height: 34px;
	}
`
const StyledTitle = styled(Text)`
	font-size: 18px;
	font-weight: 500;
	padding-block: ${SPACES.EXTRA_SMALL};
	color: ${Colors.Black};
`
export default ActionComponent
