import { Industry, TemplateType } from 'blue-project-types'

const sr = {
	common: {
		featureToggler: {
			title1: 'Promeni',
			title2: 'funkcije',
			label: 'Funkcije',
			placeholder: 'Odaberi funkcije',
		},
		lessonsToggler: {
			title1: 'Promeni',
			title2: 'lekcije',
			label: 'Lekcije',
			placeholder: 'Odaberi lekcije',
		},
		leakDomains: {
			title1: 'Promeni',
			title2: 'domene',
			label: 'Domeni',
			placeholder: 'Unesi domen',
			successMessage: 'Uspesno promenjeni domeni',
		},
		api: {
			useChangeFeature: {
				success: 'Organizacija je uspešno ažurirana',
			},
			useChangeRoles: {
				success: 'Uloge su uspešno ažurirane',
			},
			useChangeLessons: {
				success: 'Organizacija je uspešno ažurirana',
			},
			useRemoveOrganization: {
				success: 'Organizacija je uspešno uklonjena',
			},
			useRemoveUser: {
				success: 'Korisnik je uspešno uklonjen',
			},
			useRestoreOrganization: {
				success: 'Organizacija je uspešno povraćena',
			},
			useRestoreUser: {
				success: 'Korisnik je uspešno povraćen',
			},
			useRemoveLesson: {
				success: 'Lekcija je uspešno uklonjena',
			},
			useEditOrganization: {
				success: 'Organizacija je uspešno izmenjena',
				error: 'Desila se greška',
			},
			useRemoveNanoPage: {
				success: 'Nano stranica je uspešno uklonjena',
			},
		},
		pages: {
			organizations: {
				search: {
					placeholder: 'Pretraži organizacije',
				},
				column: {
					title1: 'Ime',
					title2: 'Veb adresa',
					title3: 'Datum kad je dodat',
					title4: 'Datum kad je uklonjen',
					title5: 'Funkcije',
					title6: 'Lekcije',
					group: 'Groupa',
					numOfAwLicences: 'Br. Awar. Licenci',
					leakDomains: 'Domeni',
				},
				record: {
					title: 'Promenite funkcije',
					titleLessons: 'Promenite lekcije',
					changeAwarenessLicence: 'Promenite Aw licencu',
					editOrganization: 'Izmeni',
					activityLogs: 'Aktivnosti organizacije',
					editLeakDomains: 'Promenite domene',
				},
				fetchingOrganizations: {
					title: 'Organizacije',
					placeholder: 'Pretražite pojedinačne resurse',
				},
				removingOrganization: {
					title: 'Uklonite ovu organizaciju?',
					subtitle1: 'Da li ste sigurni da želite da je uklonite',
					subtitle2: 'sa platforme?',
				},
				restoringOrganization: {
					title: 'Povratite ovu organizaciju?',
					subtitle: 'Da li ste sigurni da želite da je povratite',
				},
				changeLicence: {
					title: 'Promena licence',
					successMessage: 'Licenca uspesno sacuvana',
					form: {
						type: {
							label: 'Tip licence',
							placeholder: 'Unesite tip licence',
						},
						number: {
							label: 'Broj licenici',
							placeholder: 'Unesite broj licenci',
						},
					},
				},
				editOrganization: {
					title: 'Izmeni organizaciju',
					successMessage: 'Organizacija uspešno izmenjena',
					form: {
						name: {
							label: 'Naziv organizacije',
							placeholder: 'Unesite naziv organizacije',
						},
						webUrl: {
							label: 'Vebsajt organizacije',
							placeholder: 'Unesite vebsajt organizacije',
						},
						logo: {
							label: 'Logo organizacije',
							placeholder: 'Unesite logo organizacije',
							dragAndDropText:
								'Aploudujte logo <1>Prevucite-i-otpustite ili kliknite da dodate sa računara</1>',
						},
						group: {
							label: 'Grupa organizacije',
							placeholder: 'Unesite grupu organizacije',
						},
						button: {
							cancel: 'Otkaži',
							save: 'Sačuvaj',
						},
					},
				},
			},
			organizationDetails: {
				title: 'Detalji organizacije',
				cards: {
					url: 'URL',
					dateAdded: 'Datum dodavanja',
					features: 'Funkcije',
					numberLicences: 'Broj licenci',
				},
				licences: {
					title: 'Iskorišćenost licence',
					startingLicences: {
						title: 'Broj aktivnih licenci na početku izveštaja',
						subtitle: 'Ukopan broj aktivnih licenci u ovom periodu',
					},
					reportTrend: {
						title: 'Trend izveštaja',
						subtitle: 'Trend korišćenja od početka do kraja izveštaja',
					},
					totalDailyUsage: {
						title: 'Ukupna dnevna upotreba',
						subtitle: 'Ukupan broj licenci korišćenih po danu',
					},
				},
				graph: {
					title: 'Korišćenost licence',
					subtitle: 'Nove licence aktivirane u proslom periodu',
					legend: {
						title: 'Promena korisnika',
					},
				},
			},
			users: {
				column: {
					title1: 'Ime',
					title3: 'Mejl adresa',
					title4: 'Datum kad je dodat',
					title5: 'Datum kad je obrisan',
					title6: 'Uloge super administratora',
					placeholder: 'Bez uloga',
				},
				fetchingUsers: {
					title: 'Korisnici',
					placeholder: 'Pretražite pojedinačne resurse',
				},
				removingUser: {
					title: 'Uklonite ovog korisnika?',
					subtitle1: 'Da li ste sigurni da želite da ga uklonite',
					subtitle2: 'sa platforme?',
				},
				restoringUser: {
					title: 'Povratite ovog korisnika?',
					subtitle: 'Da li ste sigurni da želite da ga povratite?',
				},
			},
			templates: {
				column: {
					title1: 'Ime',
					title2: 'Opis',
					title3: 'Datum kad je dodat',
					title4: 'Grupe biblioteka',
					title5: 'Pregled',
					organizationName: 'Organizacija',
				},
				fetchingTemplates: {
					title: 'Konfiguracija Phishing Šablona',
					placeholder: 'Pretraži odredjeni phishing šablon',
				},
				removingTemplate: {
					title: 'Obriši ovaj phishing šablon?',
					subtitle1: 'Da li ste sigurni da želite da uklonite',
					subtitle2: 'phishing šablon sa platforme?',
				},
				actions: {
					addTemplateActionBtn: '+ Dodaj phishing šablon',
					seeTemplate: 'Pogledaj Phishing Šablon',
					editTemplate: 'Izmeni',
				},
				details: {
					name: 'Ime:',
					description: 'Opis:',
					emailBody: 'E-mail phishing šablon:',
					type: 'Tip:',
					language: 'Jezik:',
					sendingEmail: 'E-mail poslat preko:',
					emailTitle: 'E-mail Naslov:',
					emailFromName: 'E-mail Ime:',
					tags: 'Tagovi:',
				},
				type: {
					[TemplateType.CredentialHarvest]: 'Harvest kredencijala',
					[TemplateType.MalwareAttachment]: 'Malware u prilogu',
					[TemplateType.LinkInAttachment]: 'Link u prilogu',
					[TemplateType.LinkForMalware]: 'Link zajedno sa Malware-om',
					[TemplateType.DriveByUrl]: 'Direktno kroz Url',
				},
			},
			updateAssetVerification: {
				successEditMessage: 'Uspešno ažuriran status verifikacije resursa.',
			},
			createTemplate: {
				title: 'Kreiraj Phishing Šablon',
				titleEdit: 'Izmeni Phishing Šablon',
				successMessage: 'Uspešno kreiran phishing šablon!',
				successEditMessage: 'Uspešno izmenjen phishing šablon!',
				successDeleteMessage: 'Uspešno obrisan phishing šablon!',
				tooltipInfo: `Ako je tip phishing šablona 'harvest kredencijala', onda ime mora da odgovara kreiranim phishing šablonima.`,
				form: {
					name: {
						label: 'Ime',
						placeholder: 'Molimo vas unesite naziv phishing šablona',
					},
					description: {
						label: 'Opis',
						placeholder: 'Molimo vas unesite opis phishing šablona',
					},
					emailBody: {
						label: 'E-mail šablon',
						placeholder: 'Molimo vas nalepite ceo minifikovan html kȏd phishing šablona',
					},
					type: {
						label: 'Tip',
						placeholder: '',
					},
					language: {
						label: 'Jezik',
						placeholder: 'Molimo vas izaberite jezik',
					},
					tenantId: {
						label: 'Organizacija',
						placeholder: 'Molimo vas izaberite organizaciju',
						allTenants: 'Sve',
					},
					sendingEmail: {
						label: 'E-mail poslat preko',
						placeholder: 'Molimo vas unesite e-mail sa kog se šalje phishing šablon',
					},
					emailTitle: {
						label: 'E-mail Naslov',
						placeholder: 'Molimo vas unesite naslov e-maila',
					},
					emailFromName: {
						label: 'E-mail Ime',
						placeholder: 'Molimo vas unesite ime e-maila',
					},
					tags: {
						label: 'Grupe biblioteka',
						placeholder: 'Molimo vas izaberite grupe biblioteka',
					},
				},
			},
			assets: {
				fetchingAllAssets: {
					title: 'Svi resursi',
				},
				discovered: {
					manually: 'Manuelno',
					automatically: 'Automatski',
				},
				column: {
					title1: 'Resurs',
					title2: 'Organizacija',
					title3: 'Tip',
					title4: 'Nadzor',
					title5: 'Status verifikacije',
					title6: 'Otkriće',
					title7: 'Datum kada je dodat',
				},
				verified: 'Verifikovan',
				notVerified: 'Nije verifikovan',
				monitored: 'Prati se',
				notMonitored: 'Ne prati se',
				manuallyVerificationDialog: {
					titleVerify: 'Verifikuj resurs {{asset}}',
					titleUnverify: 'Ukloni verifikaciju za {{asset}} resurs',
					subtitleUnverify: 'Da li ste sigurni da želite da uklonite verifikaciju za ovaj resurs?',
					subtitleVerify: 'Da li ste sigurni da želite da verifikujete ovaj resurs?',
				},
				actions: {
					manuallyVerifyAsset: 'Verifikuj resurs',
					manuallyUnverifyAsset: 'Ukloni verifikaciju',
				},
			},
			courseIndustryStandards: {
				courseIndustryStandardsTitle: 'Industrijski standardi kurseva',
				table: {
					column: {
						industry: 'Industrija',
						score: 'Rezultat',
					},
				},
				scoreNotSet: 'Nije Setovan',
				changeScoreBtn: 'Promenite rezultat',
				changeScoreModal: {
					title: 'Ažurirajte rezultat za industriju - {{industry}}',
					subtitle: 'Unesite rezultat koji zelite da dodelite ovoj industriji',
					scoreField: {
						label: 'Rezultat',
						placeholder: 'Unesite rezultat',
					},
				},
				useChangeScore: {
					success: 'Rezultat je uspešno ažuriran!',
				},
				industry: {
					[Industry.Agriculture]: 'Poljoprivreda',
					[Industry.Automotive]: 'Automobilska industrija',
					[Industry.Banking]: 'Bankarstvo',
					[Industry.Chemicals]: 'Hemijska industrija',
					[Industry.Construction]: 'Građevinarstvo',
					[Industry.Education]: 'Obrazovanje',
					[Industry.Energy]: 'Energetika',
					[Industry.Entertainment]: 'Zabava',
					[Industry.Finance]: 'Finansije',
					[Industry.FoodAndBeverage]: 'Hrana i piće',
					[Industry.Healthcare]: 'Zdravstvo',
					[Industry.InformationTechnology]: 'Informacione tehnologije',
					[Industry.Manufacturing]: 'Proizvodnja',
					[Industry.Media]: 'Mediji',
					[Industry.Pharmaceuticals]: 'Farmaceutska industrija',
					[Industry.RealEstate]: 'Nekretnine',
					[Industry.Retail]: 'Trgovina',
					[Industry.Telecommunications]: 'Telekomunikacije',
					[Industry.Transportation]: 'Transport',
					[Industry.TravelAndTourism]: 'Putovanja i turizam',
					[Industry.Utilities]: 'Komunalne usluge',
					[Industry.Other]: 'Druge',
				},
			},
		},
	},
}

export default sr
