import {
	AssetType,
	DiscoverType,
	RecordType,
	ScoreCategory,
	PointsProperty,
} from 'blue-project-types'

const sr = {
	people: {
		common: {
			addPeople: {
				title: 'Unesite detalje polaznika',
				subtitle: 'Odaberite metod kojim ćete uvesti podatke',
				social: {
					importFrom: 'Uvezi iz',
					googleButton: 'Poveži podatke preko Google nalogom',
					googleFailed: 'Sinhronizacija sa Google nalogom neuspešna',
					microsoftButton: 'Poveži podatke preko Microsoft naloga',
					microsoftFailed: 'Sinhronizacija sa Microsoft nalogom neuspešna',
				},
				csv: {
					buttonMainText: 'Podigni CSV fajl',
					buttonSecondaryText: 'Prevuci-i-otpusti ili klikni da dodaš sa računara',
					subtitle: 'Označi ili potvrdi podatke iz CSV fajla',
					smallDescription1:
						'Potrebna nam je vaša pomoć pri utvrđivanju imena, prezimena i mejl adresa u fajlu koji ste podigli',
					smallDescription2: 'Odaberite kolonu koja sadrži korisničke podatke iz padajućeg menija.',
					notGoodMatch: 'Ne poklapa se',
					propertyNotExists: 'Ne postoji',
					example: 'Pronađite CSV primer <1>ovde</1>',
					deleteNotInList: 'Izbriši ljude koji nisu na listi',
				},
				errorCsv: 'Vaš učitan CSV fajl nije validan',
				manually: {
					button: 'Dodaj zaposlene ručno',
					smallDescription: 'Za male organizacije ili u svrhe testiranja',
					person: 'Zaposleni',
					addNextButton: 'Dodaj sledećeg zaposlenog',
				},
				properties: {
					firstName: {
						label: 'Ime',
						placeholder: 'Unesi ime',
					},
					lastName: {
						label: 'Prezime',
						placeholder: 'Unesi prezime',
					},
					email: {
						label: 'Mejl adresa',
						placeholder: 'Unesi mejl',
					},
					groups: {
						label: 'Grupe',
						placeholder: 'Unesi groupe',
					},
				},
				actions: {
					peopleAddedSuccess: 'Podaci zaposlenih su uspešno sačuvani!',
					noPeopleToBeSaved: 'Nema podataka zaposlenih za sačuvati',
				},
				organizationMustBeSet: 'Organizacija mora biti uspostavljena!',
			},
		},
		dashboard: {
			title: 'Zaposleni',
			addNewButton: '+ Dodaj zaposlene',
			search: {
				placeholder: 'Pretraži zaposlene',
			},
			table: {
				columns: {
					displayName: 'Ime',
					firstName: 'Ime',
					lastName: 'Prezime',
					email: 'Mejl adresa',
					groups: 'Grupe',
					createdAt: 'Datum kada je dodat',
				},
				actions: {
					edit: 'Uredi',
					remove: 'Ukloni',
				},
			},
			filters: {
				groups: {
					name: 'Grupe',
				},
			},
			removeModal: {
				title: 'Ukloni podatke ovog zaposlenog?',
				text: 'Da li ste sigurni da želite da uklonite <1>{{fullName}}</1> iz inventara?',
			},
			editModal: {
				title: 'Uredi podatke zaposlenog',
			},
		},
		overview: {
			title: 'Zaposleni',
			noPeople: {
				title: 'Nema zaposlenih u organizatiji',
				subtitle: 'Dodajte zaposlene u organizaciji da biste videli pregled zaposlenih',
			},
			alerts: {
				title: 'Upozorenja',
				seeAll: 'Prikaži sve',
				seeLess: 'Prikaži manje',
				noAlerts: {
					title: 'Nema upozorenja',
					subtitle: 'Trenutno nema upozorenja za Vas.',
				},
				courseNotCompleted: {
					title: 'Kurs nije završen - {{courseName}}',
					subtitle: '{{learnersCount}} zaposlenih nije zavržilo kurs pre isteka roka',
				},
				courseFailed: {
					title: 'Zaposleni pali na kursu - {{courseName}}',
					subtitle: '{{learnersCount}} zaposlenih je palo ka kursu',
				},
				simulationFailed: {
					title: 'Zaposleni pali na simulaciji - {{courseName}}',
					subtitle: '{{targetsCount}} zaposlenih je palo na phishing simulaciji',
				},
				leaks: {
					title: 'Pronađeni procureli kredencijali',
					subtitle: 'Procureli kredencijali povezani sa {{username}} su otkriveni {{exposedAt}}',
				},
			},
			statistics: {
				people: {
					title: 'Ukupno zaposlenih',
					subtitle: 'Ukupan broj zaposlenih u organizaciji',
				},
				courses: {
					title: 'Ukupno kurseva',
					subtitle: 'Ukupan broj kreiranih kurseva',
				},
				simulations: {
					title: 'Ukupno phishing simulacija',
					subtitle: 'Ukupan broj kreiranih phishing simulacija',
				},
				nanoCampaigns: {
					title: 'Ukupno nano kampanja',
					subtitle: 'Ukupan broj kreiranih nano kampanja',
				},
				domains: {
					title: 'Skenirani domeni',
					subtitle: 'Ukupan broj skeniranih domena',
				},
			},
			dailyActivitySummary: {
				title: 'Rezime dnevnih aktivnosti',
				peopleOnCourse: '{{peopleOnCourse}} aktivnih zaposlenih na kursevima danas',
				peoplePassed: '{{peoplePassed}} zaposlenih je položilo kurs danas',
				peopleFailed: '{{peopleFailed}} zaposlenih je palo na kursu danas',
				attacksSent: '{{attacksSent}} poslatih phishing šablona',
				targetsFailed: '{{targetsFailed}} zaposlenih je palo na phishing simulaciji danas',
				targetsPassed: '{{targetsPassed}} zaposlenih je uspešno prijavilo pokušaj phishing-a danas',
			},
			failedTargetsPerTemplate: {
				title: 'Pale zaposleni po šablonu',
			},
			nanoLearning: {
				title: 'Nano učenje',
				lessonsSent: 'Lekcija poslato',
			},
			awareness: {
				title: 'Zadaci',
			},
			nanoLearningStatisticsChart: {
				answeredCorrectlyCount: 'Prošli',
				nanoLessonsRepeatedCount: 'Ponovili',
				incorrectAnswerCount: 'Pali',
			},
			recentActivity: {
				title: 'Nedavna aktivnost',
			},
			people: {
				title: 'Lista zaposlenih',
			},
			dailyActivityChart: {
				failedLabel: 'Pale mete',
				openedLabel: 'Otvoreni mejlovi',
				sentLabel: 'Poslati mejlovi',
				sentContentLabel: 'Poslat sadržaj',
				engagedLabel: 'Bavilo se sadržajem',
				passedLearnersLabel: 'Zaposleni prošli kurs',
				failedLearnersLabel: 'Zaposleni pali na kursu',
			},
		},
		score: {
			tenantOverview: {
				scoreTitle: 'Seif Score',
				scoreDescription: `Merač prikazuje trenutni Seif Score vaše organizacije, uzimajući u obzir rezultate napretka obuke i kontrole curenja akreditiva. Na osnovu ovih nalaza, istaknute su oblasti za poboljšanje.`,
				scoreDescriptionTitle: 'Opis',
				scoreSummary: 'Pregled Seif Score-a',
				scoreVsLastMonth: '{{scoreChange}} u odnosu na prošli mesec',
				scoreText: {
					[ScoreCategory.VeryLow]:
						'Vaš trenutni Seif Score je 0-25 poena, kategorisan kao Veoma Nizak.',
					[ScoreCategory.Low]: 'Vaš trenutni Seif Score je 26-50 poena, kategorisan kao Nizak.',
					[ScoreCategory.Medium]:
						'Vaš trenutni Seif Score je 51-75 poena, kategorisan kao Srednji.',
					[ScoreCategory.High]: 'Vaš trenutni Seif Score je 76-95 poena, kategorisan kao Visok.',
					[ScoreCategory.VeryHigh]:
						'Vaš trenutni Seif Score je 96-100 poena, kategorisan kao Veoma Visok.',
				},
				summaryText: {
					[ScoreCategory.VeryLow]:
						'Vaša organizacija je dobila veoma nizak Seif Score, što ukazuje na hitnu potrebu za poboljšanjem edukacije i spremnosti zaposlenih u rešavanju bezbednosnih problema.',
					[ScoreCategory.Low]:
						'Predlažemo da preduzmete hitne mere za poboljšanje bezbednosnog stanja zaposlenih kako biste poboljšali vaš Seif Score.',
					[ScoreCategory.Medium]:
						'Vaša organizacija je postigla solidan Seif Score, što ukazuje na dobar nivo edukacije i spremnosti zaposlenih za rešavanje bezbednosnih problema.',
					[ScoreCategory.High]:
						'Vaša organizacija je postigla pohvalan Seif Score, što odražava visok nivo edukacije i spremnosti zaposlenih za rešavanje bezbednosnih problema.',
					[ScoreCategory.VeryHigh]:
						'Čestitamo! Vaša organizacija je postigla veoma visok Seif Score, što ukazuje da su vaši zaposleni dobro edukovani i spremni da brzo identifikuju i reše bezbednosne probleme.',
				},
				summary: {
					[ScoreCategory.VeryLow]:
						'Vaša organizacija je dobila veoma nizak Seif Score, ispod 25, što ukazuje na hitnu potrebu za poboljšanjem edukacije i spremnosti zaposlenih u rešavanju bezbednosnih problema.',
					[ScoreCategory.Low]:
						'Vaš Seif Score ukazuje na nizak nivo bezbednosne svesti. Predlažemo da odmah preduzmete mere za poboljšanje bezbednosnog stanja zaposlenih.',
					[ScoreCategory.Medium]:
						'Vaša organizacija je postigla solidan Seif Score, što ukazuje na dobar nivo edukacije i spremnosti zaposlenih za rešavanje bezbednosnih problema.',
					[ScoreCategory.High]:
						'Vaša organizacija je postigla pohvalan Seif Score, što odražava visok nivo edukacije i spremnosti zaposlenih za rešavanje bezbednosnih problema.',
					[ScoreCategory.VeryHigh]:
						'Čestitamo! Vaša organizacija je postigla veoma visok Seif Score, što ukazuje da su vaši zaposleni dobro edukovani i spremni da brzo identifikuju i reše bezbednosne probleme.',
				},
			},
			scoreProperties: {
				[PointsProperty.Courses]: 'Zadaci',
				[PointsProperty.Leaks]: 'Curenje akreditiva',
				[PointsProperty.NanoLearning]: 'Nano učenje',
				[PointsProperty.Phishing]: 'Fišing simulacije',
			},
			scorePropertiesMessages: {
				credentialLeaksNotNotified: 'curenje(a) akreditiva koje zahteva hitnu akciju',
				credentialLeaksNotified: 'curenje(a) akreditiva koje čeka da zaposleni reši',
				noAssignments: 'Osoba nije pozvana ni na jedan kurs',
				noCompletedAssignments: 'Osoba je pozvana na kurs ali ga još nije završila',
				failedAssignments: 'Osoba nije uspešno završila kurs(eve):',
				expiredAssignments: 'Osoba nije završila kurs(eve) pre roka:',
				badLessons: 'Osoba je uspešno završila kurs(eve) ali ima lekcije za poboljšanje:',
				noPhishingSimulations: 'Osoba nije deo nijedne fišing simulacije',
				phishingSimulationsFailed: 'Osoba nije prošla fišing simulaciju(e):',
				nanoLearningEngageRate: 'Stopa angažovanja u nano učenju je:',
				perfectScore: 'Ništa za prikazivanje ovde, {{firstName}} ima savršen Seif Skor',
			},
			suggestions: {
				expiredCourseSuggestions: {
					courseCreated: 'Kurs uspešno kreiran',
					expireDateExtended: 'Datum isticanja za polaznike uspešno produžen',
					noAction: 'Nijedna akcija nije specificirana',
					tooltip: `{{numOfLearners}} polaznika nije završilo "{{courseName}}". Kreirajte kurs sa istim lekcijama samo za polaznike koji nisu završili ili produžite rok za postojeći kurs kako biste poboljšali ocenu rizika`,
					button: `Produži ili ponovo kreiraj {{courseName}} za polaznike koji nisu završili`,
					modalTitle: `Produži ili ponovo kreiraj {{courseName}} za polaznike koji nisu završili`,
					modalActionsRecreate: 'Ponovo kreiraj novi kurs',
					modalActionExtend: 'Dodaj još 7 dana',
				},
				improveLessonSuggestion: {
					tooltip: `{{numOfLearners}} treba da poboljša znanje iz "{{lessonName}}". Kreirajte kurs sa samo "{{lessonName}}" lekcijom da poboljšate ocenu rizika`,
					button: `Poboljšaj {{lessonName}}`,
				},
				noCourseExistsSuggestion: {
					tooltip: `{{numOfLearners}} nisu pozvani na nijedan kurs. Kreirajte kurs i pozovite ih kako biste poboljšali ocenu rizika`,
					button: 'Kreirajte prvi kurs',
				},
				noSimulationExistsSuggestion: {
					tooltip: `{{numOfLearners}} nisu deo nijedne simulacije. Kreirajte prvu simulaciju kako biste poboljšali ocenu rizika`,
					button: 'Kreirajte prvu simulaciju',
				},
				notifyPeopleAboutLeak: {
					tooltip: `Pronađeno je curenje poverljivih informacija za {{numOfLearners}} ljudi. Obavestite ih kako bi mogli da promene lozinke i poboljšaju ocenu rizika`,
					button: 'Obavestite ljude o curenju poverljivih informacija',
				},
				recreateFailedCourse: {
					tooltip: `{{numOfLearners}} nisu položili "{{courseName}}". Kreirajte kurs sa istim lekcijama samo za one koji nisu položili kako biste poboljšali ocenu rizika`,
					button: `Ponovo kreiraj {{courseName}} za one koji nisu položili`,
					confirmBtn: `Da li ste sigurni da želite da kreirate kurs sa istim lekcijama kao "{{courseName}}" za:`,
				},
			},
		},
	},
	assets: {
		common: {
			addAssets: {
				title: 'Unesi domene i IP adrese',
				subtitle: '',
				properties: {
					domainOrIp: {
						label: 'Domeni ili IP adrese',
						placeholder: 'Unesi domen ili IP adresu',
					},
				},
				noAssetsToBeAdded: 'Nema resursa za dodavanje',
				assetAdded: '1 resurs dodat',
				assetsAdded: '{{numberOfAssets}} resursa dodato',
				organizationMustBeSet: 'Organizacija mora biti uspostavljena!',
			},
			verifyAsset: {
				title: 'Verifikuj',
				subtitle: `Verifikujte dodate resurse odabirom jedne od dve opcije navedene ispod ili u TXT formatu ili kao DNS.`,
				tabs: {
					uploadTxtFile: 'Uploaduj txt file',
					dnsTxtRecord: 'Dodajte dns txt record',
				},
				buttonSubmit: 'Da vidimo da li radi!',
				uploadTxtFile: {
					item1: {
						title: 'Preuzmite verifikacioni fajl',
						button: 'Preuzmite verifikacioni fajl',
					},
					item2: {
						title1: `Postavite preuzetu datoteku u koren svog sajta`,
						title2: `Treba vam pomoć oko ovoga? Isprobajte naš {{ name }} članak.`,
						title3: `Pokušaćemo da pristupimo datoteci na:`,
					},
					successMsg: 'je verifikovan',
					errorMsg: 'nije verifikovan',
				},
				dnsTxtRecord: {
					item1: {
						title: 'Idite na podešavanja vašeg DNS provajdera.',
					},
					item2: {
						title1: `Umetnite donji tekst kao TXT zapis u svoj DNS`,
						title2: `Niste sigurni kako to da uradite? Isprobajte naš {{ name }} članak.`,
					},
					record: {
						name: 'Ime zapisa',
						copy: 'Kopiraj zapis',
						copied: 'Kopirano',
					},
					value: {
						name: 'Vrednost',
						copy: 'Kopiran vrednost',
						copied: 'Kopirano',
					},
					infoMsg: 'Imajte na umu da ovo može da prođe do 48 horusa da promene stupe na snagu',
				},
			},
			assetType: {
				[AssetType.DOMAIN]: 'Domen',
				[AssetType.IP_ADDRESS_V4]: 'IP adresa v4',
				[AssetType.IP_ADDRESS_V6]: 'IP adresa v4',
				[AssetType.SUBDOMAIN]: 'Podomen',
			},
			discovered: {
				[DiscoverType.Manually]: 'Ručno dodat',
				[DiscoverType.Automatically]: 'Automatski dodat',
			},
			resolvingAssets: {
				proccessNotificationMessage: 'Otkrivanje pod domena i IP addressa',
				completedNotificationMessagePlural: 'Otkriveno {{numberOfAssets}} novih resursa',
				completedNotificationMessageSingular: 'Otkriven 1 novi resurs',
				noNewAssetsNotificationMessage: 'Nisu otkriveni novi resursi',
				refetchButton: 'Novi resursi su pronadjeni u toku poslednjeg pronalazenja novih resursa.',
				gotItButton: 'Zatvori',
				errorMessage: 'Greska prilikom pronalazenja subdomena i IP adresa za {{asset}}',
			},
		},
		dashboard: {
			title: 'Resursi',
			addNewButton: '+ Dodaj resurse',
			search: {
				placeholder: 'Pretraži resurse',
			},
			noVulnerabilityFound: 'Nisu pronađeni propusti',
			table: {
				columns: {
					asset: 'Resurs',
					type: 'Tip',
					discovery: 'Otkriće',
					createdAt: 'Datum kada je dodat',
					vulnerabilityOverview: 'Predgled propusta',
					monitoring: 'Nadzor',
				},
				actions: {
					viewVulnerabilities: 'Vidi propuste',
					remove: 'Ukloni',
					verify: 'Verifikuj',
					assetNotAbleToTrack: {
						assetNotVerified: 'Resurs mora biti verifikovan',
						featureNotEnabled: 'Vaša organizacija nema uključen deo aplikacije za propuste',
						notAllowed: 'Nemate dozvolu za ovu akciju',
					},
				},
				expandedContent: {
					title: 'Ukupan rezultat',
					subtitle: 'Ukupan rezultat je izračunat kao prosek rezultata navedenih ispod.',
					noDataDescription:
						'Podaci nisu jos uvek dostupni. Rezultat će biti izračunat, prilagodjen i prikazan što pre.',
					notAvailable: 'N/A',
					[PointsProperty.Courses]: {
						title: 'Kursevi',
						score: 'Rezultat',
						enrolledCourses: 'Prijavljeni kursevi',
						coursesInProgress: 'Kursevi u toku',
						passed: 'Položenih kurseva',
						expired: 'Isteklih kurseva',
						failed: 'Nepoloženih kurseva',
						noDataMessage: 'Polaznik nije dodat na kurs.',
					},
					[PointsProperty.NanoLearning]: {
						title: 'Nano learning',
						score: 'Rezultat',
						contentSent: 'Lekcija poslato',
						quizContentSent: 'Kvizova poslato',
						contentOpened: 'Otvoreno sadržaja',
						answeredCorrectly: 'Tačno odgovoreno',
						answeredIncorrectly: 'Netačno odgovoreno',
						noDataMessage: 'Polaznik nije dobio nijednu lekciju.',
					},
					[PointsProperty.Leaks]: {
						title: 'Procureli podaci',
						score: 'Rezultat',
						detected: 'Otkriveno',
						resolved: 'Rešeno',
						noDataMessage: 'Nije otkriven nijedan procureli podatak.',
						noLeaksIdentified: 'Nije identifikovan nijedan procureli podatak.',
					},
					[PointsProperty.Phishing]: {
						title: 'Phishing simulacije',
						score: 'Rezultat',
						created: 'Kreirano',
						sent: 'Poslato',
						inProgress: 'U toku',
						passed: 'Položenih simulacija',
						failed: 'Pale simulacije',
						noReaction: 'Bez reakcije',
						phishingReported: 'Phishing prijavljen',
						noDataMessage: 'Nije započeta nijedna phishing simulacija.',
					},
				},
				assetNoInfo: 'Nisu pronadjene informacije za ovaj resurs',
			},
			removeModal: {
				title: 'Ukloni ovaj resurs?',
				text: 'Da li ste sigurni da želite da uklonite ovaj resurs <1>{{name}}</1> iz inventara?',
			},
			monitorToggleModal: {
				startTitle: 'Započnite sa nadgledanjem resursa?',
				stopTitle: 'Prestanite sa nadgledanjem resursa',
				startText:
					'Da li ste sigurni da želite da započnete nadgledanje ovog resursa <1>{{name}}</1> iz inventara?',
				stopText:
					'Da li ste sigurni da želite da prestanete nadgledanje ovog resursa <1>{{name}}</1> iz inventara?',
			},
		},
		details: {
			headerInfo: {
				createdAt: 'Kreirano',
				discovered: 'Otkriveno',
			},
			ip: {
				tabs: {
					overview: 'PREGLED',
					history: 'PROŠLI PODACI',
				},
				openPorts: 'Otvoreni portovi',
				ipDetails: {
					city: 'Grad',
					country: 'Zemlja',
					organization: 'Organizacija',
					isp: 'ISP',
					lastUpdate: 'Posledni put ažurirano',
				},
				historyTable: {
					columns: {
						openPort: 'Otvoreni portovi',
						transport: 'Transport',
						protocol: 'Protokol',
						details: 'Detalji',
						firstSeen: 'Prvi put viđeno',
						lastSeen: 'Poslednji put viđeno',
					},
				},
				notLoadedYetAlert: {
					title: 'Informacije o IP adresi nisu jos uvek pronadjene',
					subtitle: 'Podaci nisu jos uvek pronadjeni ili je doslo do greske.',
				},
			},
			domain: {
				tabs: {
					dnsRecords: 'DNS ZAPISI',
					history: 'PROŠLI PODACI',
				},
				historyTable: {
					columns: {
						recordTypeToColumnName: {
							[RecordType.A]: 'IP adese',
							[RecordType.AAAA]: 'IP adrese',
							[RecordType.MX]: 'Mejl serveri',
							[RecordType.NS]: 'serveri imena',
							[RecordType.SOA]: 'SOA zapisi',
							[RecordType.TXT]: 'TXT vrednosti',
						},
						organization: 'Organizacija',
						firstSeen: 'Prvi put viđeno',
						lastSeen: 'Poslednji put viđeno',
						durationSeen: 'Trajanje vidljivosti',
					},
				},
			},
		},
	},
}

export default sr
