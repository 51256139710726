import { BusinessDays, NanoLearnerEventName } from 'blue-project-types'

const en = {
	headerData: {
		createBtn: 'Create Campaign',
	},
	createWizardNano: {
		title: 'Launch New Nano Campaign',
		subtitle:
			'Here you can schedule, add learners, and configure settings for your nano learning campaign.',
		step1: {
			title: 'Schedule Content',
			subtitle: 'Select content groups and timing',
		},
		step2: {
			title: 'Learners',
			subtitle: 'Manage learners',
		},
		step3: {
			title: 'Settings',
			subtitle: 'Configure settings',
		},
		error: 'Please populate current data.',
	},
	scheduleContent: {
		title: 'Schedule Content',
		subtitle: 'Select content groups and timing',
		btnAddContent: '+ Add content',
		columns: {
			content: 'Content',
			time: 'Time',
			actions: 'Actions',
		},
		modalContent: {
			title: 'Select content',
			selectLang: 'Select Language',
			selType: 'Select Type',
			selectGroups: 'Select Groups',
			select: 'Select time',
			selectAll: 'All',
			fixedTime: 'Fixed time',
			startDate: 'Start',
			endDate: 'End',
			submitBtn: 'Schedule',
			cancel: 'Cancel',
			contentRequiredMsg: '* Please select & transfer minimum one content in the right column',
			endDateBeforeStartError: 'End date must be after start date',
		},
		content: 'content',
		contents: 'contents',
	},
	targetsTab: {
		title: 'Choose nano learners',
		subtitle: `Add all, or adjust conditions for this campaign's attendance`,
		allPeople: 'All people',
		customGroup: 'Custom group',
		tags: {
			label: 'Select tags',
			placeholder: 'Select tags to add participants',
		},
	},
	settingsTab: {
		title: 'Campaign Settings',
		subtitle: 'Configure campaign settings',
		nameDesc: {
			title: 'Name & Description',
			subtitle: 'Name and description are also visible to learners',
			name: {
				title: 'Campaign name',
				placeholder: 'Enter name',
			},
			description: {
				title: 'Description',
				placeholder: 'Add a description',
			},
		},
		time: {
			title: 'Time Zone & Working Hours',
			subtitle: 'Set up campaign time zone and working hours',
			selectZone: 'Select time zone',
			start: 'Start',
			end: 'End',
		},
		businessDays: {
			title: 'Campaign scoping',
			subtitle: 'Select days of the week that campaign is allowed on',
			[BusinessDays.Monday]: 'Monday',
			[BusinessDays.Tuesday]: 'Tuesday',
			[BusinessDays.Wednesday]: 'Wednesday',
			[BusinessDays.Thursday]: 'Thursday',
			[BusinessDays.Friday]: 'Friday',
			[BusinessDays.Saturday]: 'Saturday',
			[BusinessDays.Sunday]: 'Sunday',
			error: 'Minimum one business is required',
		},
		alertComponent: {
			subtitlePlural: `Pages: "{{pages}}" don't support currently selected language`,
			subtitleOne: `Page: "{{page}}" doesn't support currently selected language`,
		},
		language: {
			title: 'Language',
			subtitle: 'Set up campaign language',
			selectLang: 'Select language',
		},
	},
	successfullyCreatedCampaign: {
		title: '{{campaignName}} is ready!',
		subtitle: `If you wish to make changes to your campaign, you can do it on the main page!`,
		buttonProceed: 'Proceed to Nano Learning Campaign Page',
	},
	campaignList: {
		title: 'Seif Nano Learning',
		subtitle: 'Here you can see all active and upcoming nano-learning campaigns.',
		section1: 'Active Campaigns',
		section2: 'Upcoming Campaigns',
		section3: 'Finished Campaigns',
		noResultMsg: 'There are currently no Upcoming campaigns!',
		notEnabled: {
			title: 'This feature is not enabled for your organization.',
			content1: 'To launch your first Nano Learning Campaign,',
			content2: 'please contact Seif sales team!',
			contactBtn: 'Contact us',
		},
	},
	campaignResult: {
		learners: 'Learners',
		startedOn: 'Started on',
		startingOn: 'Starting on',
		totalDates: 'Total dates',
		remainingDates: 'Remaining dates',
		engageRate: 'Engagement rate',
		stopped: 'Stopped at',
	},
	noCampaignPage: {
		title1: 'Launch your first Nano Learn Campaign',
		title2: `to improve & monitor your Organization’s security`,
		buttonCreate: 'Create Campaign',
	},
	viewCampaign: {
		subtitle:
			'Here you can review nano learning campaign results and content, manage learners, as well as configure settings.',
		tab1: 'OVERVIEW',
		tab2: 'LEARNERS',
		tab3: 'CONTENT',
		tab4: 'SETTINGS',
	},
	chart: {
		title: 'Campaign funnel',
		results: {
			totalContentProjected: 'Total content projected',
			delivered: { label: 'Delivered', tooltip: 'The number of delivered nano contents so far.' },
			opened: { label: 'Opened', tooltip: 'The number of nano content emails opened so far.' },
			quizContent: {
				label: 'Quiz content',
				tooltip: 'The number of quiz contents projected.',
			},
			sentQuizContent: {
				label: 'Sent quiz content',
				tooltip: 'The number of sent quiz contents so far.',
			},
			answered: { label: 'Answered', tooltip: 'The number of answered quiz contents.' },
			failed: {
				label: 'Failed',
				tooltip: 'The number of quiz contents on which learner answered incorrectly at least once.',
			},
			repeated: {
				label: 'Repeated',
				tooltip: 'The number of quiz contents on which learner repeated answer at least once.',
			},
		},
	},
	contentTable: {
		title: 'Most engaged content',
		name: 'Name',
		type: 'Type',
		sentOpen: 'Sent/Opened',
		engageRate: 'Engagement rate',
		score: 'Score',
		tooltipAntd: {
			openedContents: 'The number of opened contents',
			correctContents: 'The number of correct answer',
			incorrectContents: 'The number of inccorrect answer',
		},
	},
	learnersTable: {
		title: 'Most engaged learners',
		fullname: 'Name',
		engageRate: 'Engagement rate',
		score: 'Score',
		timeToEngage: 'Time to engage',
		tooltip: {
			incorrect: 'The number of inccorrect answer',
			correct: 'The number of correct answer',
		},
	},
	learnersTab: {
		title: 'All Learners',
		fullName: 'Name',
		firstName: 'First name',
		lastName: 'Last name',
		groups: 'Groups',
		score: 'Score',
		engageRate: 'Engagement rate',
		timeToEngage: 'Time to engage',
		timeFromOpenToEngage: 'Time from opening to engaging',
		actions: 'Actions',
		remove: 'Remove',
		addBtn: '+ Add learners',
		learnerDetails: {
			type: 'Type',
			scheduled: 'Scheduled',
			[NanoLearnerEventName.Sent]: 'Sent',
			[NanoLearnerEventName.Delivered]: 'Delivered',
			[NanoLearnerEventName.Opened]: 'Opened',
			[NanoLearnerEventName.AnsweredCorrectly]: 'Answered correctly',
			[NanoLearnerEventName.AnsweredIncorrectly]: 'Answered incorrectly',
			[NanoLearnerEventName.OpenedVideoQuizPage]: 'Opened video quiz page',
			[NanoLearnerEventName.VideoCompleted]: 'Video completed',
		},
		tooltip: {
			incorrect: 'The number of incorrect answer',
			correct: 'The number of correct answer',
		},
		searchPlaceholder: 'Search for specific learner',
		modalConfirm: {
			title: 'Remove this learner?',
			subtitle: 'Are you sure you want to remove <1>{{name}}</1> from nano campaign?',
		},
		modalAdd: {
			title: 'Choose your learners',
			subtitle:
				"Please be aware that by selecting the 'All People' or 'Custom Segment' options, you will only add individuals who are not currently assigned to this program.",
		},
	},
	contentTab: {
		title: 'Scheduled content',
		name: 'Name',
		type: 'Type',
		time: 'Time',
		score: 'Score',
		actions: 'Actions',
		addBtn: '+ Add content',
		edit: 'Edit',
		remove: 'Remove',
		tooltipAntd: {
			openedContents: 'The number of opened contents',
			correctContents: 'The number of correct answer',
			incorrectContents: 'The number of inccorrect answer',
		},
		modalConfirm: {
			title: 'Remove this content?',
			subtitle: 'Are you sure you want to remove this content from nano campaign?',
		},
		useRemoveContent: {
			success: 'Content successfully removed',
			error: 'Error happened',
			alertMessage: `Content can't be deleted because it is in progress.`,
		},
		useAddContent: {
			success: 'Content successfully added',
		},
		useUpdateContent: {
			success: 'Content successfully updated',
			alertMessage: `Content can't be updated because it is in progress.`,
		},
	},
	headerView: {
		subtitle:
			'Here you can schedule, add learners, and configure settings for your nano learning campaign.',
		start: 'Start',
		end: 'End',
		status: 'Status',
		actionButton: 'Stop Campaign',
		modalTitle: 'Stop this campaign?',
		modalTxt1: 'Are you sure you want to stop campaign',
		modalTxt2: `* Be aware that you won't be able to continue this campaign any more.`,
		successMsg: 'Campaign status successfully updated!',
	},
	status: {
		stopped: 'Stopped',
		running: 'Running',
		upcoming: 'Upcoming',
	},
}

export default en
