import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { fetchLeaksPeopleSummary, FETCH_LEAKS_PEOPLE_SUMMARY_KEY } from '../../utils/api'
import { IGetPeopleLeaksOverview } from 'blue-project-types'

const useFetchLeaksPeopleSummary = () => {
	const {
		data: leaksPeopleSummary,
		isFetching: isFetchingLeaksPeopleSummary,
		error: errorFetchingLeaksPeopleSummary,
	} = useQuery<IGetPeopleLeaksOverview | any, AxiosError>([FETCH_LEAKS_PEOPLE_SUMMARY_KEY], () =>
		fetchLeaksPeopleSummary(),
	)

	return {
		leaksPeopleSummary,
		isFetchingLeaksPeopleSummary,
		errorFetchingLeaksPeopleSummary,
	}
}

export default useFetchLeaksPeopleSummary
