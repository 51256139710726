import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Alert, GlobalLoader } from '../../../components'
import Title from '../../../components/Title'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import useFetchPeopleAwarenessOverview from '../../awareness/hooks/api/useFetchPeopleAwarenessOverview'
import PeopleScore from '../../inventory/components/people/seif-score/PeopleScore'
import useFetchPeopleSummary from '../../inventory/hooks/api/useFetchPeopleSummary'
import useFetchPeople from '../../inventory/hooks/useFetchPeople'
import useFetchLeaksPeopleSummary from '../../leaks/hooks/api/useFetchLeaksPeopleSummary'
import CampaignsOverview from '../../pdf-exports/components/CampaignsOverview'
import useFetchPeoplePhishingOverview from '../../simulations/hooks/api/useFetchPeoplePhishingOverview'
import KeyActionTaken from '../../inventory/components/people/seif-score/KeyActionTaken'
import SecurityActions from '../../inventory/components/people/seif-score/SecurityActions'

const DASHBOARD_BASE_KEY = 'dashboard.overview'

const OverviewV2 = () => {
	const { t } = useTranslation()

	const { people, isFetchingPeople } = useFetchPeople()

	const { peopleAwarenessOverview, isFetchingPeopleAwarenessOverview } =
		useFetchPeopleAwarenessOverview()

	const { peoplePhishingOverview, isFetchingPeoplePhishingOverview } =
		useFetchPeoplePhishingOverview()

	const { isFetchingPeopleSummary, peopleSummary } = useFetchPeopleSummary()

	const {
		leaksPeopleSummary = {
			leaks: [],
			domainsCount: 0,
			leaksResolvedThisMonth: 0,
			system: { leaksNotified: 0, leaksNotifiedThisMonth: 0 },
		},
	} = useFetchLeaksPeopleSummary()

	const isLoading =
		isFetchingPeople ||
		isFetchingPeopleAwarenessOverview ||
		isFetchingPeoplePhishingOverview ||
		isFetchingPeopleSummary

	if (isLoading) {
		return <GlobalLoader />
	}

	if (!peopleSummary?.score && !isLoading) {
		return (
			<Wrapper>
				<Alert
					title={t(`${DASHBOARD_BASE_KEY}.noSeifScoreAlert.title`)}
					subtitle={t(`${DASHBOARD_BASE_KEY}.noSeifScoreAlert.subtitle`)}
					type={'warning'}
				></Alert>
			</Wrapper>
		)
	}

	return (
		<Wrapper>
			<Title level={1} color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${DASHBOARD_BASE_KEY}.overviewTitle`)}
			</Title>
			<PeopleScore peopleSummary={peopleSummary!} people={people} />
			<SecurityActions
				leaksNotifiedCount={leaksPeopleSummary.system.leaksNotified || 0}
				expiredCoursesCount={peopleAwarenessOverview?.system.expiredCoursesCreatedBySystem || 0}
				failedCoursesCount={peopleAwarenessOverview?.system.failedCoursesCreatedBySystem || 0}
			/>
			<KeyActionTaken
				launchedCoursesCount={peopleAwarenessOverview?.allCoursesCount || 0}
				launchedSimulationsCount={peoplePhishingOverview?.allSimulationsCount || 0}
				launchedNanoCampaignsCount={peopleAwarenessOverview?.allNanoCampaignsCount || 0}
				solvedLeaksCount={leaksPeopleSummary.leaksResolved || 0}
			/>
			<CampaignsOverview
				peoplePhishingOverview={peoplePhishingOverview!}
				peopleAwarenessOverview={peopleAwarenessOverview!}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: ${Colors.BackgroundGray};
`

export default OverviewV2
