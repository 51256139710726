import { Suggestion, SuggestionActionTimeFrame } from 'blue-project-types'

const sr = {
	phishingExport: {
		title: 'Phishing pdf izvestaj - {{simulation}}',
		overview: {
			overview: 'Pregled',
			templates: 'Predlozi',
			simulationFunnel: 'Simulacija levka',
		},
		targets: {
			title: 'Ciljevi',
			failedSimulations: 'Neuspešne simulacije',
			failedDesc:
				'Ljudi koji nisu uspeli adekvatno da reaguju na e-poštu sa zlonamernim linkovima ili unosom osetljivih podataka.',
			reported: 'Prijavljivanje phishing-a',
			reportedDesc: 'Ljudi koji su uspešno prepoznali i prijavili phishing e-poruke iz simulacije.',
			notReportedDesc:
				'Ljudi koji nisu prijavili phishing e-poruke iz simulacije nakon što su se susreli s njima.',
			notReported: 'Nije prijavljeno',
			table: {
				firstName: 'Ime',
				lastName: 'Prezime',
				simulationsSent: 'Poslate simulacije',
				simulationsFailed: 'Neuspešne simulacije',
				simulationsPassed: 'Uspesne simulacije',
			},
		},
	},
	courseOverviewExport: {
		title: 'Pdf izvestaj zadatka - {{courseName}}',
		overview: 'Pregled',
		lessons: 'Lekcije',
		learners: 'Polaznici',
		nonCompleted: 'Polaznici koji nisu zavrsili kurs',
		nonCompletedDescription: `Polaznici koji su pozvani na kurs, ali ga nisu završili u određenom roku.`,
		passed: 'Polaznici koji su prošli kurs',
		passedDescription: 'Polaznici koji su uspešno završili i položili kurs.',
		failed: 'Polaznici koji su pali kurs',
		failedDescription: `Polaznici koji su pozvani na kurs, ali ga nisu uspešno završili.`,
	},
	exportLanguageSelectionModal: {
		title: 'Izvezi u pdf',
		emailLanguageSelectLabel: 'Jezik korišćen u pdf-u',
		emailLanguageSelectPlaceholder: 'Izaberi jezik koji će biti korišćen u pdf-u',
		emailLanguageOptions: {
			en: 'Engleski',
			sr: 'Srpski',
		},
	},
	SeifScoreExport: {
		noSeifScoreAlert: {
			title: 'Seif Score nije dostupan',
			subtitle:
				'Seif Score za ovu organizaciju nije izračunat, ili je došlo do greške prilikom preuzimanja. Molimo vas da pokušate ponovo kasnije ili kontaktirajte podršku za pomoć.',
		},
		title: 'Izveštaj o Seif Score-u',
		dateOfReport: 'Datum izveštaja: {{reportDate}}',
		executiveSummary: 'Izvršni rezime',
		keyAction: {
			title: 'Ključne preduzete akcije',
			subtitle:
				'Prioritetne akcije za rešavanje kritičnih ranjivosti, poboljšanje obuka i upravljanje slabostima u poverljivosti.',
			courses: {
				title: 'Kursevi o svesti o bezbednosti',
				description: 'Pokrenuti novi kursevi za svest o sajber bezbednosti',
			},
			simulations: {
				title: 'Phishing simulacije',
				description: 'Pokrenute phishing simulacije za zaposlene',
			},
			nanoCampaigns: {
				title: 'Seif Nano učenje',
				description: 'Pokrenute kampanje Nano učenja',
			},
			leaks: {
				title: 'Rešeni slučajevi curenja poverljivih informacija',
				description: 'Rešena kritična curenja poverljivih informacija',
			},
		},
		recommendedActions: {
			title: 'Preporučene akcije',
			subtitle:
				'Preporučene akcije potrebne za održavanje bezbednosti organizacije i zaštitu osetljivih podataka.',
			subtitleNoActions: 'Izvršene su sve predložene akcije ',
			list: {
				title: {
					[SuggestionActionTimeFrame.Immediate]: 'Hitne akcije (Danas)',
					[SuggestionActionTimeFrame.ShortTerm]: 'Kratkoročne akcije (Sledećih 7 dana)',
					[SuggestionActionTimeFrame.LongTerm]: 'Dugoročne akcije (Sledećih 30 dana)',
				},
				subtitle: {
					[SuggestionActionTimeFrame.Immediate]:
						'Hitna akcija je potrebna za održavanje bezbednosti organizacije i zaštitu vaših osetljivih podataka.',
					[SuggestionActionTimeFrame.ShortTerm]:
						'Preduzmite ove akcije uskoro kako biste održali bezbednost organizacije.',
					[SuggestionActionTimeFrame.LongTerm]:
						'Planirajte ove akcije za dugoročna poboljšanja u bezbednosti.',
				},
			},
		},
		trainingCampaignsOverview: {
			title: 'Pregled rezultata obuke',
			courses: {
				title: 'Sažetak kurseva',
				subtitle:
					'Ključne metrike iz nedavnih kampanja za obuku, uključujući završavanje kurseva, učinak polaznika i ukupne stope uspeha.',
				completionRate: {
					title: 'Stopa završavanja kurseva',
					subtitle: 'Procenat polaznika koji su završili kurs, bez obzira na rezultat.',
				},
				passingRate: {
					title: 'Stopa prolaznosti kurseva',
					subtitle: 'Stopa prolaznosti polaznika u odnosu na ukupan broj polaznika.',
				},
			},
			nanoQuiz: {
				title: 'Rezultati Seif Nano kvizova',
				subtitle:
					'Ključne metrike iz prethodnog Seif Nano ciklusa, koje prikazuju angažman polaznika i stopu prolaznosti kvizova.',
				engagementRate: {
					title: 'Stopa Nano angažmana',
					subtitle: 'Procenat polaznika koji su odgovorili na kviz  prema ukupnom broju polaznika.',
				},
				passingRate: {
					title: 'Seif Nano kviz',
					subtitle: 'Broj tačnih odgovora prema ukupnom broju odgovora.',
				},
			},
			simulations: {
				title: 'Rezultati phishing simulacija',
				subtitle:
					'Rezultati simulacije phishing napada koji prikazuju učestalost napada, stopu interakcije sa mejlovima, i procenat "upecanih" zaposlenih.',
				progress: {
					total: 'Ukupan broj napada',
					clicked: 'Kliknuto',
					failed: 'Upecano',
					opened: 'Otvoreno',
				},
			},
		},
		credentialLeakMonitoring: {
			title: 'Nadzor nad curenjem poverljivih informacija',
			subtitle:
				'Pregled otkrivenih curenja poverljivih informacija i broja sanitizovanih curenja putem hitnih akcija.',
			detected: 'Detektovano procurelih kredencijala',
			unresolved: 'Kredencijali koji su i dalje izloženi',
			resolved: 'Kredencijali koji više nisu izloženi',
			notification: 'Otkrivena slaba lozinka, potrebna je promena politike.',
		},
	},
}

export default sr
