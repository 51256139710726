import { AclEvents, Industry, LicenceEvents, TenantEvents, UserEvents } from 'blue-project-types'

const en = {
	common: {
		organizationFields: {
			name: {
				label: 'Organization name',
				placeholder: 'Enter organization name',
			},
			webUrl: {
				label: 'Organization website',
				placeholder: 'Enter organization website',
			},
			logo: {
				label: 'Organization logo',
				placeholder: 'Enter organization logo',
				dragAndDropText: "Upload logo <1>Drag'n'drop or click to add from computer</1>",
			},
			group: {
				label: 'Organization group',
				placeholder: 'Enter organization group',
			},
			industry: {
				label: 'Organization industry',
				placeholder: 'Enter organization industry',
				[Industry.Agriculture]: 'Agriculture',
				[Industry.Automotive]: 'Automotive',
				[Industry.Banking]: 'Banking',
				[Industry.Chemicals]: 'Chemicals',
				[Industry.Construction]: 'Construction',
				[Industry.Education]: 'Education',
				[Industry.Energy]: 'Energy',
				[Industry.Entertainment]: 'Entertainment',
				[Industry.Finance]: 'finance',
				[Industry.FoodAndBeverage]: 'Food and beverage',
				[Industry.Healthcare]: 'healthcare',
				[Industry.InformationTechnology]: 'Information technology',
				[Industry.Manufacturing]: 'Manufacturing',
				[Industry.Media]: 'Media',
				[Industry.Pharmaceuticals]: 'Pharmaceuticals',
				[Industry.RealEstate]: 'Real estate',
				[Industry.Retail]: 'Retail',
				[Industry.Telecommunications]: 'Telecommunications',
				[Industry.Transportation]: 'Transportation',
				[Industry.TravelAndTourism]: 'Travel and tourism',
				[Industry.Utilities]: 'Utilities',
				[Industry.Other]: 'Other',
			},
		},
	},
	active: {
		title: 'Manage Organization',
		changeOrganizationDetailsButton: 'Change Organization Details',
		changeOrganizationModal: {
			title: 'Change Organization Details',
			confirm: 'Save Changes',
		},
		activeUsers: {
			title: 'Users',
			addUsersButton: '+ Add New Users',
			addUsersModal: {
				title: 'Add new users',
				subtitle: "Add new user's emails.",
				description:
					'Once they set up their account, we will notify you. Their data will be visible in user section, where you can configure the access roles.',
				fields: {
					emails: {
						label: 'Emails',
						placeholder: 'Enter emails',
					},
					roles: {
						label: 'Roles',
						placeholder: 'Select roles',
					},
				},
				confirm: 'Add New users',
				actionMessage: {
					success: 'Requests sent!',
					alreadyPartOfOrganization:
						'User is already part of organization or waiting for response!',
				},
			},
			removeConfirm: {
				title: 'Remove this user?',
				text: 'Are you sure you want to remove <1>{{fullName}}</1> from the organization?',
			},
			table: {
				columns: {
					fullName: 'Name',
					firstName: 'First name',
					lastName: 'Last name',
					email: 'Email',
					roles: 'Roles',
				},
				messages: {
					roleRequired: 'One role is required!',
					rolesChanged: 'Roles successfully changed.',
				},
				actions: {
					remove: 'Remove',
					saveRoles: 'Save',
				},
			},
		},
		pendingInvitations: {
			title: 'Pending Invitations',
			table: {
				columns: {
					email: 'Email',
					invitedByUser: 'Invited by',
					createdAt: 'Invited at',
				},
				actions: {
					resendInvitation: 'Resend invitation',
				},
			},
			resendInvitationConfirm: {
				title: 'Resend invitation to this email?',
				text: 'Are you sure you want to resend invitation to email <1>{{email}}</1> ?',
				success: 'Invitation sent!',
				alreadyPartOfOrganization: 'User is already part of organization or waiting for response!',
			},
		},
	},
	add: {
		title: 'Add Organization',
		steps: {
			details: {
				title: 'Organization details',
				description: 'Add organization data',
			},
			people: {
				title: 'People',
				description: 'Add employees data',
			},
			domains: {
				title: 'Domains',
				description: 'Add online resources',
			},
		},
		detailsStep: {
			title: 'Enter your organization details',
		},
		finishStep: {
			title: '{{name}} is now ready!',
			description1:
				'You can now create your courses, add people and assets to run Seif.ai continuous cybersecurity assessment.',
			description2:
				'All added assets are available for preview & settings in the inventory section on the platform.',
			goToButton: 'Go to inventory',
		},
		errors: {
			mustSetupOrganization: 'Must set up organization first',
		},
	},
	activityLogs: {
		title: 'Activity logs',
		column: {
			eventName: 'Event name',
			eventDescription: 'Event description',
			userName: 'User name',
			ipAdress: 'IP adress',
			data: 'Data',
			createdAt: 'Created at',
			description: {
				[UserEvents.UserCreated]: '{{actionUserFullName}} created account on the platform.',
				[UserEvents.UserLoggedIn]: '{{actionUserFullName}} logged in on the platform.',
				[UserEvents.UserLoggedOut]: '{{actionUserFullName}} logged out from the platform.',
				[UserEvents.UserLoginFailed]: '{{actionUserFullName}} failed to login on the platform.',
				[UserEvents.UserVerifiedAccount]: '{{actionUserFullName}} verified account.',
				[UserEvents.UserRequestedVerificationEmail]:
					'{{actionUserFullName}} requested new verification email.',
				[UserEvents.UserRequestedResetPassword]:
					'{{actionUserFullName}} requested reset password email.',
				[UserEvents.UserResetPassword]: '{{actionUserFullName}} reset password.',
				[UserEvents.UserTurnedOnTwoFactorAuth]:
					'{{actionUserFullName}} turned on two factor authentication.',
				[UserEvents.UserTurnedOffTwoFactorAuth]:
					'{{actionUserFullName}} turned off two factor authentication.',
				[UserEvents.UserRequestedNewTwoFactorAuthRecoveryKeys]:
					'{{actionUserFullName}} requested new two factor auth recovery keys',
				[UserEvents.UserUpdatedPassword]: '{{actionUserFullName}} updated password.',
				[UserEvents.UserFailedToUpdatePassword]:
					'{{actionUserFullName}} failed to update password.',
				[UserEvents.UserUpdatedProfile]: '{{actionUserFullName}} updated profile info.',
				[UserEvents.AdminRemovedUser]:
					'{{actionUserFullName}} removed {{removedUserFullName}} from the platform.',
				[UserEvents.AdminRestoredUser]:
					'{{actionUserFullName}} restored {{restoredUserFullName}} on the platform.',
				[TenantEvents.AdminRemovedTenant]:
					'{{actionUserFullName}} removed organization from the platform.',
				[TenantEvents.AdminRestoredTenant]:
					'{{actionUserFullName}} restored organization on the platform.',
				[TenantEvents.TenantCreated]:
					'{{actionUserFullName}} created organization on the platform.',
				[TenantEvents.FeatureChangedForTenant]:
					'{{actionUserFullName}} changed features for the organization.',
				[TenantEvents.UsersInvited]:
					'{{actionUserFullName}} invited {{invitationUsers}} to the organization.',
				[TenantEvents.UserAcceptedInvitation]: '{{actionUserFullName}} accepted invitation.',
				[TenantEvents.TenantUpdated]: '{{actionUserFullName}} organization information.',
				[TenantEvents.AdminUpdatedTenant]: '{{actionUserFullName}} organization information.',
				[LicenceEvents.LicenceCreated]: '{{actionUserFullName}} created licence.',
				[LicenceEvents.LicenceUpdated]: '{{actionUserFullName}} updated licence.',
				[LicenceEvents.LicenceDeleted]: '{{actionUserFullName}} deleted licence.',
				[AclEvents.UserRolesChanged]:
					'{{actionUserFullName}} updated {{updatedUserFullName}} roles.',
				[AclEvents.AdminRolesChanged]:
					'{{actionUserFullName}} updated {{updatedUserFullName}} roles.',
			},
		},
		filter: {
			search: {
				placeholder: 'Search by user',
			},
			group: {
				title: 'Group',
			},
			eventName: {
				[UserEvents.UserCreated]: 'User Created',
				[UserEvents.UserLoggedIn]: 'User Logged In',
				[UserEvents.UserLoggedOut]: 'User Logged Out',
				[UserEvents.UserLoginFailed]: 'User Login Failed',
				[UserEvents.UserVerifiedAccount]: 'User Verified Account',
				[UserEvents.UserRequestedVerificationEmail]: 'User Requested Verification Email',
				[UserEvents.UserRequestedResetPassword]: 'User Requested Reset Password',
				[UserEvents.UserResetPassword]: 'User Reset Password',
				[UserEvents.UserTurnedOnTwoFactorAuth]: 'User Turned On Two Factor Auth',
				[UserEvents.UserTurnedOffTwoFactorAuth]: 'User Turned Off Two Factor Auth',
				[UserEvents.UserRequestedNewTwoFactorAuthRecoveryKeys]:
					'User Requested New Two Factor Auth Recovery Keys',
				[UserEvents.UserUpdatedPassword]: 'User Updated Password',
				[UserEvents.UserFailedToUpdatePassword]: 'User Failed To Update Password',
				[UserEvents.UserUpdatedProfile]: 'User Updated Profile',
				[UserEvents.AdminRemovedUser]: 'Admin Removed User',
				[UserEvents.AdminRestoredUser]: 'Admin Restored User',
				[AclEvents.UserRolesChanged]: 'User Roles Changed',
				[AclEvents.AdminRolesChanged]: 'Admin Roles Changed',
				[TenantEvents.AdminRemovedTenant]: 'Admin Removed Tenant',
				[TenantEvents.AdminRestoredTenant]: 'Admin Restored Tenant',
				[TenantEvents.TenantCreated]: 'Tenant Created',
				[TenantEvents.FeatureChangedForTenant]: 'Feature Changed For Tenant',
				[TenantEvents.AdminUpdatedTenant]: 'Admin Updated Tenant',
				[LicenceEvents.LicenceCreated]: 'Licence Created',
				[LicenceEvents.LicenceUpdated]: 'LicenceUpdated',
				[LicenceEvents.LicenceDeleted]: 'LicenceDeleted',
				[TenantEvents.UsersInvited]: 'Users Invited',
				[TenantEvents.UserAcceptedInvitation]: 'User Accepted Invitation',
				[TenantEvents.TenantUpdated]: 'Tenant Updated',
			},
			groupNames: {
				UserManagement: 'User Management',
				UserAdministration: 'User Administration',
				AclManagement: 'Acl Management',
				TenantAdministration: 'Tenant Administration',
				TenantManagement: 'Tenant Management',
			},
		},
	},
	acceptInvitation: {
		errors: {
			notValid: 'Invitation not valid',
			generic: 'Error happened',
		},
	},
	switching: {
		messageWithName: 'Switching to {{name}}...',
		messageWithoutName: 'Switching...',
	},
}

export default en
