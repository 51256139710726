import {
	IAsset,
	IPersonScoreOverviewResponse,
	PointsProperty,
	VerificationType,
} from 'blue-project-types'

import { IAssetDiscoveryData } from '../context/context'

export const NEW_ADDED_ASSETS_QUERY_KEY = 'newly-added-assets'

export interface IAccessToken {
	accessToken: string
	tokenId: string
}

export interface ISavePeopleRequest {
	people: ICreateUpdatePerson[]
	deleteNotInList?: boolean
}

export interface ISaveAssetsRequest {
	assets: Partial<IAsset>[]
}

export interface ICreateUpdatePerson {
	id?: string
	firstName?: string
	lastName?: string
	email?: string
	tags: string[]
}

export interface IFetchPeopleQueryData {
	withDeleted?: boolean
	withRiskScore?: boolean
}

export interface IExtendedAsset extends IAsset {
	discoveryAssetsData?: IAssetDiscoveryData
	highlighted?: boolean
}

export interface IAssetVerificationType {
	verificationType: VerificationType
}

export type OverviewByProperty = {
	[PointsProperty.Courses]: IPersonScoreOverviewResponse['courses']
	[PointsProperty.NanoLearning]: IPersonScoreOverviewResponse['nanoLearning']
	[PointsProperty.Phishing]: IPersonScoreOverviewResponse['phishing']
	[PointsProperty.Leaks]: IPersonScoreOverviewResponse['leaks']
}

export interface IOverviewItem {
	label: string
	value: number
}

export type DataBuilder<T> = (t: (key: string) => string, overview: T) => IOverviewItem[]
