import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Suggestion } from 'blue-project-types'

import Text from '../../../../../components/Text'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import { Description } from '../StyledComponents'
import { ReactComponent as LeaksNotifiedIcon } from '../../../../../assets/leaks-count.svg'
import { ReactComponent as CampaignsCountIcon } from '../../../../../assets/campaigns-count.svg'
import { ReactComponent as SimulationsCountIcon } from '../../../../../assets/simulation-count.svg'
import ActionComponent from './ActionComponent'

interface IProps {
	leaksNotifiedCount: number
	expiredCoursesCount: number
	failedCoursesCount: number
}

const TRANSLATE_KEY_ACTION_BASE_KEY = 'pdfExports.SeifScoreExport.securityActions'

const SecurityActions: React.FC<IProps> = ({
	leaksNotifiedCount,
	expiredCoursesCount,
	failedCoursesCount,
}) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Text color={Colors.Black} zeroMargin style={{ fontSize: 24, fontWeight: 500 }}>
				{t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.title`)}
			</Text>
			<Description style={{ width: '50%' }}>
				{t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.subtitle`)}
			</Description>
			<ActionWrapper>
				<ActionComponent
					icon={<LeaksNotifiedIcon />}
					count={leaksNotifiedCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.${Suggestion.NotifyPeopleAboutLeak}.title`)}
					description={t(
						`${TRANSLATE_KEY_ACTION_BASE_KEY}.${Suggestion.NotifyPeopleAboutLeak}.description`,
					)}
				/>
				<ActionComponent
					icon={<SimulationsCountIcon />}
					count={expiredCoursesCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.${Suggestion.RecreateExpiredCourse}.title`)}
					description={t(
						`${TRANSLATE_KEY_ACTION_BASE_KEY}.${Suggestion.RecreateExpiredCourse}.description`,
					)}
				/>
				<ActionComponent
					icon={<CampaignsCountIcon />}
					count={failedCoursesCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.${Suggestion.RecreateFailedCourse}.title`)}
					description={t(
						`${TRANSLATE_KEY_ACTION_BASE_KEY}.${Suggestion.RecreateFailedCourse}.description`,
					)}
				/>
			</ActionWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	background: ${Colors.White};
	padding: ${SPACES.EXTRA_LARGE} 48px;
`
const ActionWrapper = styled.section`
	display: flex;
	justify-content: space-between;
`

export default SecurityActions
