import { Industry, TemplateType } from 'blue-project-types'

const en = {
	common: {
		featureToggler: {
			title1: 'Change',
			title2: 'features',
			label: 'Features',
			placeholder: 'Select features',
		},
		lessonsToggler: {
			title1: 'Change',
			title2: 'lessons',
			label: 'Lessons',
			placeholder: 'Select lessons',
		},
		leakDomains: {
			title1: 'Change',
			title2: 'leak domains',
			label: 'Domains',
			placeholder: 'Enter leak domains',
			successMessage: 'Leak domains successfully changed',
		},
		api: {
			useChangeFeature: {
				success: 'Organization successfully updated',
			},
			useChangeRoles: {
				success: 'Successfully changed roles',
			},
			useChangeLessons: {
				success: 'Organization successfully updated',
			},
			useRemoveOrganization: {
				success: 'Organization successfully removed',
			},
			useRemoveUser: {
				success: 'User successfully removed',
			},
			useRestoreOrganization: {
				success: 'Organization successfully restored',
			},
			useRestoreUser: {
				success: 'User successfully restored',
			},
			useRemoveLesson: {
				success: 'Lesson successfully removed',
			},
			useEditOrganization: {
				success: 'Organization successfully edited',
				error: 'Error happened',
			},
			useRemoveNanoPage: {
				success: 'Nano page successfully removed',
			},
		},
		pages: {
			organizations: {
				search: {
					placeholder: 'Search organizations',
				},
				column: {
					title1: 'Name',
					title2: 'Web url',
					title3: 'Date added',
					title4: 'Date deleted',
					title5: 'Features',
					title6: 'Lessons',
					group: 'Group',
					numOfAwLicences: 'No. of Awar. Licences',
					leakDomains: 'Leak domains',
				},
				record: {
					title: 'Change features',
					titleLessons: 'Change lessons',
					changeAwarenessLicence: 'Change Aw Licence',
					editOrganization: 'Edit',
					activityLogs: 'Organization activity',
					editLeakDomains: 'Change Leak Domains',
				},
				fetchingOrganizations: {
					title: 'Organizations',
					placeholder: 'Search for specific asset',
				},
				removingOrganization: {
					title: 'Remove this organization?',
					subtitle1: 'Are you sure you want to remove',
					subtitle2: 'from the platform?',
				},
				restoringOrganization: {
					title: 'Restore this organization?',
					subtitle: 'Are you sure you want to restore',
				},
				changeLicence: {
					title: 'Change licence',
					successMessage: 'Licence succefully saved',
					form: {
						type: {
							label: 'Licence type',
							placeholder: 'Enter licence type',
						},
						number: {
							label: 'Number of licence',
							placeholder: 'Enter number of licence',
						},
					},
				},
				editOrganization: {
					title: 'Edit organization',
					successMessage: 'Organization succefully edited',
					form: {
						name: {
							label: 'Organization name',
							placeholder: 'Enter organization name',
						},
						webUrl: {
							label: 'Organization website',
							placeholder: 'Enter organization website',
						},
						logo: {
							label: 'Organization logo',
							placeholder: 'Enter organization logo',
							dragAndDropText: "Upload logo <1>Drag'n'drop or click to add from computer</1>",
						},
						group: {
							label: 'Organization group',
							placeholder: 'Enter organization group',
						},
						button: {
							cancel: 'Cancel',
							save: 'Save',
						},
					},
				},
			},
			organizationDetails: {
				title: 'Organization details',
				cards: {
					url: 'URL',
					dateAdded: 'Date Added',
					features: 'Features',
					numberLicences: 'No. of awareness licences',
				},
				licences: {
					title: 'Licences usage',
					startingLicences: {
						title: 'Starting licences',
						subtitle: 'Total No. of active licences at start of report',
					},
					reportTrend: {
						title: 'Report trend',
						subtitle: 'Usage trend for report from start to end',
					},
					totalDailyUsage: {
						title: 'Total daily licence usage',
						subtitle: 'Total No. of licences used per day for report period',
					},
				},
				graph: {
					title: 'Licences usage',
					subtitle: 'New licences activated in past period',
					legend: {
						title: 'User change',
					},
				},
			},
			users: {
				column: {
					title1: 'Name',
					title3: 'Email',
					title4: 'Date added',
					title5: 'Date deleted',
					title6: 'Super admin roles',
					placeholder: 'No roles',
				},
				fetchingUsers: {
					title: 'Users',
					placeholder: 'Search for specific asset',
				},
				removingUser: {
					title: 'Remove this user?',
					subtitle1: 'Are you sure you want to remove',
					subtitle2: 'from the platform?',
				},
				restoringUser: {
					title: 'Restore this user?',
					subtitle: 'Are you sure you want to restore',
				},
			},
			templates: {
				column: {
					title1: 'Name',
					title2: 'Description',
					title3: 'Date added',
					title4: 'Library groups',
					title5: 'Preview',
					organizationName: 'Organization',
				},
				fetchingTemplates: {
					title: 'Phishing Templates Configuration',
					placeholder: 'Search for specific phishing template',
				},
				removingTemplate: {
					title: 'Remove this phishing template?',
					subtitle1: 'Are you sure you want to remove',
					subtitle2: 'phishing template from the platform?',
				},
				actions: {
					addTemplateActionBtn: '+ Add phishing template',
					seeTemplate: 'See Phishing Template',
					editTemplate: 'Edit',
				},
				details: {
					name: 'Name:',
					description: 'Description:',
					emailBody: 'E-mail body:',
					type: 'Type:',
					language: 'Language:',
					sendingEmail: 'E-mail Sending Over:',
					emailTitle: 'E-mail Title:',
					emailFromName: 'E-mail from Name:',
					tags: 'Tags:',
				},
				type: {
					[TemplateType.CredentialHarvest]: 'Credential Harvest',
					[TemplateType.MalwareAttachment]: 'Malware Attachment',
					[TemplateType.LinkInAttachment]: 'Link in Attachment',
					[TemplateType.LinkForMalware]: 'Link for Malware',
					[TemplateType.DriveByUrl]: 'Drive by Url',
				},
			},
			updateAssetVerification: {
				successEditMessage: 'Successfuly updated asset verification status.',
			},
			createTemplate: {
				title: 'Create Phishing Template',
				titleEdit: 'Edit Phishing Template',
				successMessage: 'Phishing template successfully created!',
				successEditMessage: 'Phishing template successfully edited!',
				successDeleteMessage: 'Phishing template successfully deleted!',
				tooltipInfo: `If the phishing template type is 'credential harvest', then the name must match the created phishing templates.`,
				form: {
					name: {
						label: 'Name',
						placeholder: 'Please insert name for phishing template',
					},
					description: {
						label: 'Description',
						placeholder: 'Please insert description for phishing template',
					},
					emailBody: {
						label: 'E-mail body',
						placeholder: 'Please paste all html minify code for phishing template',
					},
					type: {
						label: 'Type',
						placeholder: '',
					},
					language: {
						label: 'Language',
						placeholder: 'Please select language',
					},
					tenantId: {
						label: 'Organization',
						placeholder: 'Please select organization',
						allTenants: 'All',
					},
					sendingEmail: {
						label: 'E-mail Sending Over',
						placeholder: 'Please insert e-mail sending over',
					},
					emailTitle: {
						label: 'E-mail Title',
						placeholder: 'Please insert e-mail title',
					},
					emailFromName: {
						label: 'E-mail from Name',
						placeholder: 'Please insert e-mail from name',
					},
					tags: {
						label: 'Library groups',
						placeholder: 'Please select library groups',
					},
				},
			},
			assets: {
				fetchingAllAssets: {
					title: 'All assets',
				},
				discovered: {
					manually: 'Manually',
					automatically: 'Auto-Discovered',
				},
				column: {
					title1: 'Asset',
					title2: 'Organization',
					title3: 'Type',
					title4: 'Monitoring',
					title5: 'Verification status',
					title6: 'Discovery',
					title7: 'Date added',
				},
				verified: 'Verified',
				notVerified: 'Not Verified',
				monitored: 'Monitored',
				notMonitored: 'Not monitored',
				manuallyVerificationDialog: {
					titleVerify: 'Verify asset {{asset}}',
					titleUnverify: 'Remove verification for {{asset}} asset',
					subtitleUnverify: 'Are you sure to remove verification for this asset?',
					subtitleVerify: 'Are you sure to verify asset?',
				},
				actions: {
					manuallyVerifyAsset: 'Verify asset',
					manuallyUnverifyAsset: 'Unverify asset',
				},
			},
			courseIndustryStandards: {
				courseIndustryStandardsTitle: 'Course industry standards',
				table: {
					column: {
						industry: 'Industry',
						score: 'Score',
					},
				},
				scoreNotSet: 'Not Set',
				changeScoreBtn: 'Change score',
				changeScoreModal: {
					title: 'Update score for industry - {{industry}}',
					subtitle: 'Enter score you wish to assign for this industry',
					scoreField: {
						label: 'Score',
						placeholder: 'Enter score',
					},
				},
				useChangeScore: {
					success: 'Score updated successfully!',
				},
				industry: {
					[Industry.Agriculture]: 'Agriculture',
					[Industry.Automotive]: 'Automotive',
					[Industry.Banking]: 'Banking',
					[Industry.Chemicals]: 'Chemicals',
					[Industry.Construction]: 'Construction',
					[Industry.Education]: 'Education',
					[Industry.Energy]: 'Energy',
					[Industry.Entertainment]: 'Entertainment',
					[Industry.Finance]: 'finance',
					[Industry.FoodAndBeverage]: 'Food and beverage',
					[Industry.Healthcare]: 'healthcare',
					[Industry.InformationTechnology]: 'Information technology',
					[Industry.Manufacturing]: 'Manufacturing',
					[Industry.Media]: 'Media',
					[Industry.Pharmaceuticals]: 'Pharmaceuticals',
					[Industry.RealEstate]: 'Real estate',
					[Industry.Retail]: 'Retail',
					[Industry.Telecommunications]: 'Telecommunications',
					[Industry.Transportation]: 'Transportation',
					[Industry.TravelAndTourism]: 'Travel and tourism',
					[Industry.Utilities]: 'Utilities',
					[Industry.Other]: 'Other',
				},
			},
		},
	},
}

export default en
