import {
	ICourse,
	ICourseMessages,
	ILesson,
	ILessonToCourse,
	IPerson,
	Language,
	ILearner,
	LearnerEventCode,
	CourseReminder,
	PageType,
	IPageContent,
	PredefinedCourseLevel,
	NanoPageType,
	INanoPageContent,
} from 'blue-project-types'

export interface IPredefinedPeople {
	id: string
	name: string
}

export interface ICreateCourseAddLearners {
	people: 'all' | 'tags' | 'ids'
	tags: string[]
	ids?: string[]
}

export interface ICreateCourseSettings {
	lang: Language
	enrollmentDate: string
	enrollmentTime: string
	enrollmentDateTime?: string
	durationWeeks: number
	durationDays?: number
	name: string
	description: string
}

export interface ICreateCourse {
	lessons?: string[]
	learners?: ICreateCourseAddLearners
	settings?: ICreateCourseSettings
	messages?: ICourseMessages
	predefinedCourseId?: string
}

export interface ICopyCourse {
	learners: ICreateCourseAddLearners
	id: string
}

export interface IExtendLearnersDuration {
	learners: string[]
	numberOfDays: number
}

export interface IExtendedCourse extends ICourse {
	learners: IExtendedLearner[]
}

export interface IExtendedLearnerData {
	displayName: string
	dueDate: Date
	completionRate: number
	timeSpent: number
	status: LearnerStatus
	daysLeft: number
	quizResult?: QuizResult
	isExpired: boolean
}

export type IExtendedLearner = ILearner & Partial<IPerson> & IExtendedLearnerData

export type IExtendedLesson = ILesson & Partial<ILessonToCourse>

export interface IRemoveLearnerData {
	learnerId: string
	courseId: string
}

export interface ICourseLearnerData {
	learnerId: string
	courseId: string
}

export interface IUpdateLearnerData {
	learnerId: string
	courseId: string
	enrollmentDate?: string
	durationDays?: number
	enrollmentTime?: string
	durationWeeks?: number
}

export interface ILessonQuizDetails {
	name: string
	pages: Array<{
		name: string
		answers: Array<{ code: LearnerEventCode }>
	}>
}

export interface ICourseLearnersStatistic {
	id: string
	completionRate: number
	timeSpent: number
	score?: number
}

export enum LearnerStatus {
	InvitationPending = 'InvitationPending',
	InvitationSentNoActivity = 'InvitationSentNoActivity',
	InvitationOpened = 'InvitationOpened',
	LearningInProgress = 'LearningInProgress',
	LearningCompleted = 'LearningCompleted',
}

export interface IResendEmailsData {
	courseId: string
	learnersIds: string[]
}

export interface ISendReminderData {
	courseId: string
	learnersIds: string[]
	reminderType: CourseReminder
}

export enum QuizResult {
	Passed = 'Passed',
	Failed = 'Failed',
}

export interface IGeneratePreviewLinkData {
	lessonId: string
	courseId: string
}

export interface IBasicCourseWithStatistic extends ICourse {
	statistic: {
		averageScore: number
		completionRate: number
	} | null
}

export enum CourseType {
	Basic = 'basic',
	// ASEEAdvanced = 'ASEEAdvanced',
	// OWASPTop10 = 'OWASPTop10',
}

export interface ICreateEditPage {
	type: PageType
	name: string
	tenantId?: string
	pageContents: IPageContent[]
}
export interface ICreateEditLesson {
	name: string
	tenantId: string
	isPremium: boolean
	lessonContents: ICreateEditLessonContent[]
	expectedCompletionTime: number
	tags?: string[]
}
export interface ICreateEditLessonContent {
	title: string
	lang: string
}

export interface IRemoveLessonPage {
	lessonId: string
	pageId: string
}

export enum PageMode {
	View = 'view',
	Edit = 'edit',
	NotAllowed = 'notAllowed',
}

export interface IPredefinedCourseCreateEditForm {
	name: string
	tenantId: string
	image?: string
	avatarImage?: string
	level: PredefinedCourseLevel
	passingScore: number
	isPremium: boolean
	lessonIds: string[]
	predefinedCourseContents: IPredefinedCourseContents[]
}

export interface IPredefinedCourseContents {
	name: string
	description: string
	lang: Language
}

export interface ICreateEditNanoPage {
	name: string
	type: NanoPageType
	tenantId: string
	tags: string[]
	isPremium: boolean
	nanoPageContents: INanoPageContent[]
}
export enum AwarenessStatus {
	InProgress = 'in_progress',
	Upcoming = 'upcoming',
	Finished = 'finished',
}
